<template>
  <span>
    <b-button-group size="sm">
      <b-button
        variant="outline-primary"
        v-for="(action, index) in actions"
        :key="index"
        @click="
          (event) => {
            action.action(params);
          }
        "
      >
        <feather-icon :icon="action.icon" />
        <span class="align-middle">{{ $t(action.name) }}</span>
      </b-button>
    </b-button-group>
  </span>
</template>
  
  <script>
import { BButtonGroup, BButton, BModal, BCardText, BRow, BCol, BDropdown, BDropdownItem } from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import useApiServices from '@/services/useApiServices.js';

export default {
  components: {
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BModal,
    BCardText,
    BDropdown,
    BDropdownItem,
    AgGridTable,
    BrunaElementLoader
  },

  directives: {
  },

  data: function ()
  {
    return {
      actions: []
    };
  },
  beforeMount()
  {
    this.actions = this.params.actions
  },

  methods: {

    // gets called whenever the user gets the cell to refresh
    refresh(params)
    {
      // set value into cell again
      //this.params = params
      this.actions = params.actions
    },

  }
};
  </script>
  
  <style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
  