<template>
    <div class="ag-cell-wrapper" role="presentation">


        <b-spinner v-show="cellValue == 'RUNNING'" variant="info" type="border" small class="mr-25"></b-spinner>
  
        <span>{{ cellValue }}</span>


    </div>
</template>

<script>
import { BTooltip, BButtonGroup, BButton, VBTooltip, BDropdown, BDropdownItem, BFormCheckbox, BSpinner } from "bootstrap-vue";

export default {
    components: {
        BTooltip,
        BButtonGroup,
        BButton,
        VBTooltip,
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        BSpinner
    },

    directives: {
        "b-tooltip": VBTooltip
    },

    data: function ()
    {
        return {
            cellValue: null
        };
    },
    beforeMount()
    {
        //console.log(this.params)

        // this.params contains the cell & row information and is made available to this component at creation time
        // see ICellRendererParams below for more details
        this.cellValue = this.getValueToDisplay(this.params);


    },
    methods: {
        // gets called whenever the user gets the cell to refresh
        refresh(params)
        {
            // set value into cell again
            //this.cellValue = this.getValueToDisplay(params);
            this.cellValue = this.getValueToDisplay(params);
        },


        getValueToDisplay(params)
        {

            return params.value;
            //return params.valueFormatted ? params.valueFormatted : params.value;
        },


    }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
