<template>
  <input ref="input" type="checkbox" :checked="value"  @input="onInput" />
</template>

<script>

import Vue from 'vue';

import
{
  BFormInput,
  BFormCheckbox
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

import
{
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length, numeric, decimal
} from '@validations'


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormCheckbox
  },

  directives: {
  },

  data()
  { 
    return {
      value: false
    };
  },
  methods: {

    onInput(event)
    {

      let checked = event.target.checked

      this.value = checked;

      let colId = this.params.column.colId;
      this.params.node.setDataValue(colId, checked);
    },

    /* Component Editor Lifecycle methods */
    // the final value to send to the grid, on completion of editing
    getValue()
    {
      // this simple editor doubles any value entered into the input
      return this.value;
    },

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart()
    {
      //return false;
    },

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd()
    {
      //return false;
    }
  },
  mounted()
  {
    this.value = this.params.value;
    //Vue.nextTick(() => this.$refs.input.focus());
  }


};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
