<template>
<validation-observer>
  <validation-provider #default="{ errors }" name="" rules="numeric2">
    <b-form-input
      ref="input"
      v-model="value"
      :state="errors.length > 0 ? false : null"
      placeholder=""
    />
    <!--
    <small class="text-danger">{{ errors[0] }}</small>
    -->
  </validation-provider>


</validation-observer>

</template>

<script>

import Vue from 'vue';

import
{
  BFormInput,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

import
{
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length, numeric, decimal
} from '@validations'


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput
  },

  directives: {
  },

  data()
  {
    return {
      value: null
    };
  },
  methods: {
    /* Component Editor Lifecycle methods */
    // the final value to send to the grid, on completion of editing
    getValue()
    {
      // this simple editor doubles any value entered into the input
      return this.value;
    },

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart()
    {
      return false;
    },

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd()
    {
      if (isNaN(this.value))
      {
        return true;
      }

      return false;


    }
  },
  mounted()
  {
    this.value = this.params.value;
    Vue.nextTick(() => this.$refs.input.focus());
  }


};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
