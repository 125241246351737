<template>

<!--
    <b-button-group size="sm">
        <b-button
            variant="outline-primary"
            v-b-tooltip.hover.bottom="'Filtrar filas'"
            v-on:click="event => {}"
        >
            <feather-icon icon="FilterIcon" />
        </b-button>
        <b-button
            variant="outline-primary"
            v-b-tooltip.hover.bottom="'Ocultar Columnas'"
        >
            <feather-icon icon="EyeIcon" />
        </b-button>
        <b-button
            variant="outline-primary"
            v-b-tooltip.hover.bottom="'Configurar'"
        >
            <feather-icon icon="SettingsIcon" />
        </b-button>

        <b-button
            variant="outline-primary"
            v-b-tooltip.hover.bottom="'Exportar'"
        >
            <feather-icon icon="SaveIcon" />
        </b-button>
    </b-button-group>
-->

  <!-- Column: Actions -->

        <div class="text-nowrap">

          <feather-icon
            icon="SendIcon"
            class="cursor-pointer mx-1"
            size="16"
            v-b-tooltip.hover.bottom="'Configurar'"
            v-on:click="event => {
                buttonClicked();
            }"
          />

          <feather-icon
            icon="EyeIcon"
            class="cursor-pointer mx-1"
            size="16"
            v-b-tooltip.hover.bottom="'Configurar'"
            v-on:click="event => {
                buttonClicked();
            }"
          />

          <feather-icon
            icon="SettingsIcon"
            class="cursor-pointer mx-1"
            size="16"
            v-b-tooltip.hover.bottom="'Configurar'"
            v-on:click="event => {
                buttonClicked();
            }"
          />


        
        </div>   


 



</template>

<script>
import { BTooltip, BButtonGroup, BButton, VBTooltip, BDropdown, BDropdownItem } from "bootstrap-vue";

export default {
    components: {
        BTooltip,
        BButtonGroup,
        BButton,
        VBTooltip,
        BDropdown,
        BDropdownItem,
    },

    directives: {
        "b-tooltip": VBTooltip
    },

    data: function() {
        return {
            cellValue: null
        };
    },
    beforeMount() {
        // this.params contains the cell & row information and is made available to this component at creation time
        // see ICellRendererParams below for more details
        this.cellValue = this.getValueToDisplay(this.params);

        console.log("customActionsCellRender", this.cellValue)

    },
    methods: {
        // gets called whenever the user gets the cell to refresh
        refresh(params) {
            // set value into cell again
            //this.cellValue = this.getValueToDisplay(params);
            this.cellValue = this.getValueToDisplay(params);
        },

        buttonClicked() {
            console.log(this.cellValue);
        },

        getValueToDisplay(params) {
            console.log({ params });

            return params;
            //return params.valueFormatted ? params.valueFormatted : params.value;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
