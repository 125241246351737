<template>
  <div class="text-nowrap">
    <feather-icon
      v-for="(action, index) in actions"
      :key="index"
      :icon="action.icon"
      class="cursor-pointer mx-1"
      size="16"
      v-b-tooltip.hover.bottom="action.name"
      v-on:click="
        (event) => {
          action.action(params);
        }
      "
    />
  </div>

</template>

<script>
import { BTooltip, BButtonGroup, BButton, VBTooltip, BDropdown, BDropdownItem } from "bootstrap-vue";

export default {
  components: {
    BTooltip,
    BButtonGroup,
    BButton,
    VBTooltip,
    BDropdown,
    BDropdownItem,
  },

  directives: {
    "b-tooltip": VBTooltip
  },

  data: function ()
  {
    return {
      actions: []
    };
  },
  beforeMount()
  {
    this.actions = this.params.actions
  },

  methods: {

    // gets called whenever the user gets the cell to refresh
    refresh(params)
    {
      // set value into cell again
      //this.params = params
      this.actions = params.actions
    },

  }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
